import {NoSsr} from '@/components/common-components';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import {useTranslation} from 'next-i18next';
import {observer} from 'mobx-react-lite';
import Head from 'next/head';
import {ErrorBoundary} from '../../components/ErrorBoundary';
import {getLocation} from '@/utils/string';
import {RegisterPage} from '@/components/dashboard/account/register';

const Register = observer(({...props}) => {
  const {t} = useTranslation('common');
  return (
    <>
      <Head>
        <title>{t('register')} | {getLocation(location)}</title>
      </Head>
      <NoSsr>
        <ErrorBoundary>
          <RegisterPage {...props} />
        </ErrorBoundary>
      </NoSsr>
    </>
  );
});


export const getStaticProps = async ({locale}) => ({
  props: {
    ...await serverSideTranslations(locale, ['common', 'components', 'register', 'settings', 'login']),
  },
});


export default Register;
